<template>
  <!-- table -->
  <xm-flex>
    <xm-flex-item>
      <xm-search-box>
        <el-button size="small" type="primary" @click="add">新增内容项</el-button>
      </xm-search-box>
    </xm-flex-item>
    <xm-flex-item :flex="1">
      <el-scrollbar view-style="height: calc(100% - 50px)">
        <el-table :data="table.data.posterTemplateItemList" height="100%" border stripe>
          <el-table-column prop="id" label="ID" width="80"></el-table-column>
          <el-table-column prop="typeName" width="60" label="类型"></el-table-column>
          <el-table-column prop="tagName" width="100" label="内容"></el-table-column>
          <el-table-column prop="name" width="100" label="名称"></el-table-column>
          <el-table-column label="示例" width="92">
            <template #default="scope">
              <el-image style="width: 70px; height: 70px" :src="scope.row.sample" fit="contain" :preview-src-list="[scope.row.sample]" :initial-index="1" v-if="scope.row.type === 1"></el-image>
              <div v-else>{{scope.row.sample}}</div>
            </template>
          </el-table-column>
          <el-table-column label="属性">
            <template #default="scope">
              <div v-if="scope.row.type === 1">{{`坐标${scope.row.x}px*${scope.row.y}px，宽高${scope.row.width}px*${scope.row.height}px，圆角：${scope.row.round ? '是' : '否'}`}}</div>
              <div v-else-if="scope.row.type === 2">{{`坐标${scope.row.x}px*${scope.row.y}px，宽${scope.row.width}px，行高${scope.row.height}px，行数：${scope.row.lineNum}，对齐：${scope.row.align}，字体：${scope.row.size}px，加粗：${scope.row.bold ? '是' : '否'}，颜色：${scope.row.color}`}}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template #default="scope">
              <el-button type="text" size="small" @click="modify(scope.row)">修改</el-button>
              <el-dropdown @command="handleCommand">
                <el-button type="text" size="small" class="el-dropdown-link">更多</el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'up'}" v-if="!scope.row._isFirst">上移</el-dropdown-item>
                    <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'down'}" v-if="!scope.row._isLast">下移</el-dropdown-item>
                    <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'top'}" v-if="!scope.row._isFirst">置顶</el-dropdown-item>
                    <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'bottom'}" v-if="!scope.row._isLast">置底</el-dropdown-item>
                    <el-dropdown-item :command="{name: 'clear', id: scope.row.id}">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-scrollbar>
    </xm-flex-item>
  </xm-flex>
  <!-- dialog -->
  <el-dialog v-model="dialog.visible" :title="dialog.title" destroy-on-close>
    <el-form ref="ruleForm" :model="dialog.data" label-width="auto">
      <!-- 名称 -->
      <el-form-item label="名称" prop="name" :rules="[
        {required:true, message: '名称不能为空',trigger: 'blur'}
      ]">
        <el-input v-model="dialog.data.name" size="small" clearable></el-input>
      </el-form-item>
      <!-- 素材类型 -->
      <el-form-item label="素材类型" prop="type" :rules="[
        {required:true, message: '素材类型不能为空',trigger: 'blur'}
      ]">
        <el-select v-model="dialog.data.type" placeholder="请选择素材类型" size="small" clearable>
          <el-option label="图片" value="1"></el-option>
          <el-option label="文本" value="2"></el-option>
        </el-select>
      </el-form-item>
      <!-- 内容类型(图片) -->
      <el-form-item label="内容类型" prop="tag" :rules="[
        {required:true, message: '内容类型不能为空',trigger: 'blur'}
      ]" v-if="dialog.data.type == '1'">
        <el-select v-model="dialog.data.tag" placeholder="请选择内容类型" size="small" clearable>
          <el-option label="头像" value="MEMBER_IMAGE"></el-option>
          <el-option label="名片头像" value="CARD_HEADIMG"></el-option>
          <el-option label="小程序码" value="QRCODE"></el-option>
          <el-option label="封面图" value="IMAGE"></el-option>
          <el-option label="名片图" value="CARD_IMAGE"></el-option>
          <el-option label="助力封面图" value="ASSIST_IMAGE"></el-option>
          <el-option label="自定义图片" value="ORIGINAL_IMAGE"></el-option>
        </el-select>
      </el-form-item>
      <!-- 内容类型(文本) -->
      <el-form-item label="内容类型" prop="tag" :rules="[
        {required:true, message: '内容类型不能为空',trigger: 'blur'}
      ]" v-else-if="dialog.data.type == '2'">
        <el-select v-model="dialog.data.tag" placeholder="请选择内容类型" size="small" clearable>
          <el-option label="用户名" value="MEMBER_NAME"></el-option>
          <el-option label="名片名" value="CARD_NAME"></el-option>
          <el-option label="名称" value="NAME"></el-option>
          <el-option label="职位" value="TITLE"></el-option>
          <el-option label="手机号" value="PHONE"></el-option>
          <el-option label="地址" value="ADDRESS"></el-option>
          <el-option label="企业名" value="INSTITUTION_NAME"></el-option>
          <el-option label="邮箱" value="EMAIL"></el-option>
          <el-option label="自定义文本" value="ORIGINAL_TEXT"></el-option>
          <el-option label="单位" value="UNIT"></el-option>
          <el-option label="价格" value="PRICE"></el-option>
          <el-option label="拼团价格" value="GROUP_BUY_PRICE"></el-option>
          <el-option label="优惠百分比" value="DISCOUNT_PERCENT"></el-option>
        </el-select>
      </el-form-item>
      <!-- 图片 -->
      <template v-if="dialog.data.type == '1'">
        <!-- 示例图 -->
        <el-form-item label="示例图" prop="sample" :rules="[
          {required: !dialog.data.sample, message: '示例图不能为空', trigger: 'blur'}
        ]">
          <el-upload action="" :http-request="uploadImage" :on-exceed="exceedImage" :limit="1" list-type="picture">
            <el-button size="small" type="default">上传图片</el-button>
          </el-upload>
        </el-form-item>
        <!-- x坐标 -->
        <el-form-item label="x坐标(px)" prop="x" :rules="[
          {required:true, message: 'x坐标不能为空',trigger: 'blur'}
        ]">
          <el-input v-model="dialog.data.x" size="small" type="number" clearable></el-input>
        </el-form-item>
        <!-- y坐标 -->
        <el-form-item label="y坐标(px)" prop="y" :rules="[
          {required:true, message: 'y坐标不能为空',trigger: 'blur'}
        ]">
          <el-input v-model="dialog.data.y" size="small" type="number" clearable></el-input>
        </el-form-item>
        <!-- 宽度 -->
        <el-form-item label="宽度(px)" prop="width" :rules="[
          {required:true, message: '宽度不能为空',trigger: 'blur'}
        ]">
          <el-input v-model="dialog.data.width" size="small" type="number" clearable></el-input>
        </el-form-item>
        <!-- 高度 -->
        <el-form-item label="高度(px)" prop="height" :rules="[
          {required:true, message: '高度不能为空',trigger: 'blur'}
        ]">
          <el-input v-model="dialog.data.height" size="small" type="number" clearable></el-input>
        </el-form-item>
        <!-- 是否圆角 -->
        <el-form-item label="是否圆角" prop="round" :rules="[
          {required:true, message: '是否圆角不能为空',trigger: 'blur'}
        ]">
          <el-select v-model="dialog.data.round" placeholder="请选择是否圆角" size="small" clearable>
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <!-- 文本 -->
      <template v-else-if="dialog.data.type == '2'">
        <!-- 示例文本 -->
        <el-form-item label="示例文本" prop="sample" :rules="[
          {required:true, message: '示例文本不能为空',trigger: 'blur'}
        ]">
          <el-input v-model="dialog.data.sample" size="small" maxlength="128" clearable></el-input>
        </el-form-item>
        <!-- x坐标 -->
        <el-form-item label="x坐标(px)" prop="x" :rules="[
          {required:true, message: 'x坐标不能为空',trigger: 'blur'}
        ]">
          <el-input v-model="dialog.data.x" size="small" type="number" clearable></el-input>
        </el-form-item>
        <!-- y坐标 -->
        <el-form-item label="y坐标(px)" prop="y" :rules="[
          {required:true, message: 'y坐标不能为空',trigger: 'blur'}
        ]">
          <el-input v-model="dialog.data.y" size="small" type="number" clearable></el-input>
        </el-form-item>
        <!-- 宽度 -->
        <el-form-item label="宽度(px)" prop="width" :rules="[
          {required:true, message: '宽度不能为空',trigger: 'blur'}
        ]">
          <el-input v-model="dialog.data.width" size="small" type="number" clearable></el-input>
        </el-form-item>
        <!-- 行高 -->
        <el-form-item label="行高(px)" prop="height" :rules="[
          {required:true, message: '行高不能为空',trigger: 'blur'}
        ]">
          <el-input v-model="dialog.data.height" size="small" type="number" clearable></el-input>
        </el-form-item>
        <!-- 行数 -->
        <el-form-item label="行数" prop="lineNum" :rules="[
          {required:true, message: '行数不能为空',trigger: 'blur'}
        ]">
          <el-input v-model="dialog.data.lineNum" size="small" type="number" clearable></el-input>
        </el-form-item>
        <!-- 对齐 -->
        <el-form-item label="对齐方式" prop="align" :rules="[
          {required:true, message: '对齐方式不能为空',trigger: 'blur'}
        ]">
          <el-select v-model="dialog.data.align" placeholder="请选择对齐方式" size="small" clearable>
            <el-option label="左对齐" value="left"></el-option>
            <el-option label="居中" value="center"></el-option>
            <el-option label="右对齐" value="right"></el-option>
          </el-select>
        </el-form-item>
        <!-- 字体大小 -->
        <el-form-item label="字体大小(px)" prop="size" :rules="[
          {required:true, message: '字体大小不能为空',trigger: 'blur'}
        ]">
          <el-input v-model="dialog.data.size" size="small" type="number" clearable></el-input>
        </el-form-item>
        <!-- 加粗 -->
        <el-form-item label="字体加粗" prop="bold" :rules="[
          {required:true, message: '字体加粗不能为空',trigger: 'blur'}
        ]">
          <el-select v-model="dialog.data.bold" placeholder="请选择字体加粗方式" size="small" clearable>
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>
        <!-- 字体颜色 -->
        <el-form-item label="字体颜色" prop="color" :rules="[
          {required:true, message: '字体颜色不能为空',trigger: 'blur'}
        ]">
          <el-input v-model="dialog.data.color" size="small" clearable></el-input>
        </el-form-item>
      </template>
    </el-form>
    <template #footer>
      <el-button @click="dialog.visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="confirm" size="small">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { ElButton, ElDialog, ElSelect, ElOption, ElTableColumn, ElForm, ElFormItem, ElInput, ElDropdown, ElDropdownItem, ElDropdownMenu, ElTable, ElUpload, ElImage, ElScrollbar } from 'element-plus'
import { reactive, ref, onMounted } from 'vue'
import axios from '@/common/utils/axios'
import upload from '@/common/utils/upload'
import type from '@/common/utils/type'

function table (posterTemplateId) {
  const table = reactive({
    data: {
      posterTemplateId,
      posterTemplateItemList: null
    }
  })
  /**
     * 查询表单列表
     */
  const queryData = () => {
    return new Promise((resolve) => {
      axios('/manager/poster/template/item/list', {
        posterTemplateId: table.data.posterTemplateId
      })
        .then(res => {
          table.data.posterTemplateItemList = res.posterTemplateItemList.map(posterTemplateItem => {
            let typeName = ''
            let tagName = ''
            if (posterTemplateItem.type === type().posterTemplateItemType.image) {
              typeName = '图片'
            } else if (posterTemplateItem.type === type().posterTemplateItemType.text) {
              typeName = '文本'
            }
            switch (posterTemplateItem.tag) {
              case 'MEMBER_IMAGE':
                tagName = '头像'
                break
              case 'CARD_HEADIMG':
                tagName = '名片头像'
                break
              case 'MEMBER_NAME':
                tagName = '用户名'
                break
              case 'CARD_NAME':
                tagName = '名片名'
                break
              case 'MEMBER_TIP':
                tagName = '用户提示'
                break
              case 'QRCODE':
                tagName = '小程序码'
                break
              case 'QRCODE_TIP':
                tagName = '小程序码提示'
                break
              case 'IMAGE':
                tagName = '封面图'
                break
              case 'NAME':
                tagName = '名称'
                break
              case 'TITLE':
                tagName = '职位'
                break
              case 'PHONE':
                tagName = '手机号'
                break
              case 'ADDRESS':
                tagName = '地址'
                break
              case 'INSTITUTION_NAME':
                tagName = '企业名'
                break
              case 'EMAIL':
                tagName = '邮箱'
                break
              case 'ASSIST_IMAGE':
                tagName = '助力封面图'
                break
              case 'ORIGINAL_IMAGE':
                tagName = '图片'
                break
              case 'ORIGINAL_TEXT':
                tagName = '文本'
                break
              case 'UNIT':
                tagName = '单位'
                break
              case 'PRICE':
                tagName = '价格'
                break
              case 'GROUP_BUY_PRICE':
                tagName = '拼团价格'
                break
              case 'DISCOUNT_PERCENT':
                tagName = '优惠百分比'
                break
              default:
                break
            }
            return {
              typeName,
              tagName,
              ...posterTemplateItem
            }
          })
          resolve()
        }).catch(_ => { })
    })
  }
  onMounted(async () => {
    await queryData(table.data.posterTemplateId)
  })

  return {
    queryData,
    table
  }
}

function dialog () {
  const ruleForm = ref(null)
  const dialog = reactive({
    visible: false,
    title: '',
    data: {}
  })
  return {
    ruleForm,
    dialog
  }
}

function operation (t, d) {
  /**
     * 新增
     */
  const add = () => {
    d.dialog.visible = true
    d.dialog.title = '新增内容项'
    d.dialog.data = {
      posterTemplateId: t.table.data.posterTemplateId
    }
  }
  /**
     * 修改
     */
  const modify = (row) => {
    d.dialog.visible = true
    d.dialog.title = '修改内容项'
    d.dialog.data = {
      posterTemplateItemId: row.id,
      posterTemplateId: t.table.data.posterTemplateId,
      name: row.name,
      type: row.type.toString(),
      tag: row.tag,
      sample: row.sample,
      x: row.x,
      y: row.y,
      width: row.width,
      height: row.height,
      lineNum: row.lineNum,
      round: row.round ? '1' : '0',
      align: row.align,
      size: row.size,
      bold: row.bold ? '1' : '0',
      color: row.color
    }
  }
  /**
   * 保存图文模板
   */
  const confirm = () => {
    d.ruleForm.value.validate(valid => {
      if (!valid) {
        return
      }
      submit()
    })
  }
  const submit = () => {
    axios('/manager/poster/template/item/save', d.dialog.data)
      .then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        d.dialog.visible = false
        t.queryData()
      }).catch(_ => { })
  }
  /**
     * 指令列表
     */
  const handleCommand = (command) => {
    commandList[command.name](command.id, command.action)
  }
  const commandList = {
    clear: (id) => { // 删除
      window.$confirm('是否确认删除').then(() => {
        del(id)
      }).catch(_ => { })
    },
    move: (id, action) => {
      axios('/manager/poster/template/item/move', {
        posterTemplateItemId: id,
        move: action
      }).then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        t.queryData()
      })
    }
  }
  const del = (id) => {
    axios('/manager/poster/template/item/del', {
      posterTemplateItemId: id
    })
      .then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        t.queryData()
      }).catch(_ => { })
  }
  /**
   * 上传图片
   */
  const uploadImage = ({ file }) => {
    upload(file, '0', (url) => {
      d.dialog.data.sample = url
    })
  }
  /**
   * 超出数量提示
   */
  const exceedImage = () => {
    window.$message({
      message: '图片超出数量，请删除后上传',
      type: 'error'
    })
  }
  return {
    add,
    modify,
    confirm,
    handleCommand,
    uploadImage,
    exceedImage
  }
}

export default {
  components: { ElButton, ElDialog, ElSelect, ElOption, ElTableColumn, ElForm, ElFormItem, ElInput, ElDropdown, ElDropdownItem, ElDropdownMenu, ElTable, ElUpload, ElImage, ElScrollbar },
  props: {
    posterTemplateId: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const t = table(props.posterTemplateId)
    const d = dialog()
    const o = operation(t, d)
    return {
      ...t,
      ...d,
      ...o
    }
  }
}
</script>
<style lang="scss" scoped>
.el-table {
  margin-top: 15px;
}
</style>
